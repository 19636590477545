<template>
  <div class="operate-header-box">
    <img class="icon1" :src="icon1" alt="icon">
    <img class="icon2" :src="icon2" alt="icon">
    <img class="icon3" :src="icon3" alt="icon">
    <img class="icon4" :src="icon4" alt="icon">
    <img class="icon5" :src="icon5" alt="icon">
    <img class="icon6" :src="icon3" alt="icon">
    <p>做好检查，确保设备正常运行</p>
    <i class="zero1 zero"></i>
    <i class="zero2 zero"></i>
  </div>
</template>

<script>
  import icon1 from '@/assets/img/check/operate-icon1.png'
  import icon2 from '@/assets/img/check/operate-icon2.png'
  import icon3 from '@/assets/img/check/operate-icon3.png'
  import icon4 from '@/assets/img/check/operate-icon4.png'
  import icon5 from '@/assets/img/check/operate-icon5.png'
  export default {
    name: "OperateHeader",
    data () {
      return {
        icon1: icon1,
        icon2: icon2,
        icon3: icon3,
        icon4: icon4,
        icon5: icon5
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/mixin";
  .operate-header-box{
    @include wh(100%, 8rem);
    background:linear-gradient(90deg,rgba(79,147,255,1) 0%,rgba(58,187,253,1) 100%);
    position: relative;
    i.zero{
      position: absolute;
      width:0.4rem;
      height:0.4rem;
      border:0.05rem solid rgba(255,255,255,1);
      opacity:0.7;
      border-radius:50%;
      display: block;
    }
    i.zero1{
      left: 6rem;
      top: 2rem;
    }
    i.zero2{
      left: 11rem;
      top: 2rem;
    }
    img{
      position: absolute;
    }
    img.icon1{
      width: 5rem;
      left: 0;
      bottom: 0;
    }
    img.icon2{
      width: 1rem;
      left: 7rem;
      top: 1.5rem;
    }
    img.icon3{
      width: 1.4rem;
      right: 3rem;
      top: 1.5rem;
    }
    img.icon4{
      width: 1rem;
      left: 7rem;
      bottom: 1.5rem;
    }
    img.icon5{
      width: 0.5rem;
      left: 10rem;
      bottom: 1rem;
    }
    img.icon6{
      width: 0.8rem;
      right: 3rem;
      bottom: 1.5rem;
    }
    p{
      position: absolute;
      top: 3.2rem;
      left: 6rem;
      font-size: 0.8rem;
      color: white;
      opacity: 0.7;
    }
  }
</style>
