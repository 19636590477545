<template>
  <div class="operate-panel-wrapper">
    <div class="panel-title">
      <div class="left">{{index+'、'}}&nbsp;{{data.checkItemName}}</div>
      <div class="right" v-if="operation=='yes'">
        <span :class="`${data.checkStatus === 1 ? 'active1' : ''}`" @click="handleSelectCheckStatus(1, data.id)">正常</span>
        <span :class="`${data.checkStatus === 2 ? 'active' : ''}`" @click="handleSelectCheckStatus(2, data.id)">异常</span>
      </div>
      <div class="right" v-else>
        <span :class="`${data.checkStatus === 1 ? 'active1' : ''}`" v-if="data.checkStatus === 1">正常</span>
        <span :class="`${data.checkStatus === 2 ? 'active' : ''}`" v-else>异常</span>
      </div>
    </div>
    <div class="panel-content">
      <div class="tip">{{data.checkItemDetail}}</div>
      <div class="tip-img" v-if="data.checkWayType !== 1">
        <van-uploader :readonly="operation=='no'" v-model="fileList" :after-read="uploadImg" multiple />
      </div>
      <div class="remark">
        <van-field :readonly="operation=='no'" v-model="data.abnormalDes" rows="1" autosize label="描述：" type="textarea" :placeholder="operation=='no'?'':'请输入描述'" />
      </div>
      <div class="remark">
        <van-field :readonly="operation=='no'" v-model="data.remark" rows="1" autosize label="备注：" type="textarea" :placeholder="operation=='no'?'':'请输入备注'" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { Field, Uploader } from 'vant'
import { imgUrl, QiNiuUrl } from '@/config/env'
import { getQiNiuToken } from '@/api/common'

Vue.use(Field, Uploader)
export default {
  name: 'OperatePanel',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    index: {
      type: [String, Number],
      default: '',
    },
    operation: {
      type: String,
      default: 'yes',
    },
  },
  watch: {
    data: {
      handler(val) {
        this.message = val.remark

        let abnormalPicUrl = val.abnormalPicUrl
        if (abnormalPicUrl) {
          const arr = abnormalPicUrl.split(',')
          this.fileList = []
          arr.forEach((item) => {
            this.fileList.push({
              url: imgUrl + item,
              isImage: true,
            })
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      message: '',
      token: '',
      fileList: [],
    }
  },
  mounted() {
    this.getQiNiuToken()
  },
  methods: {
    /**
     * 选择点检状态
     */
    handleSelectCheckStatus(status, id) {
      this.$emit('handleSelectCheckStatus', status, id)
    },
    /**
     * 获取七牛云token
     */
    getQiNiuToken() {
      getQiNiuToken().then((res) => {
        this.token = res.qiniuToken
      })
    },
    /**
     * 上传图片
     */
    uploadImg(file) {
      let content = file.file
      let data = new FormData()
      data.append('file', content)
      data.append('token', this.token)
      axios.post(QiNiuUrl, data).then((res) => {
        const data = res.data
        this.$emit('handleUploadImg', data.hash, this.data.id)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.operate-panel-wrapper {
  padding: 0 1rem;
  background-color: white;
  margin-bottom: 1rem;
  .panel-title {
    @include wh(100%, 2.2rem);
    border-bottom: 1px solid #f1f1f1;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      font-size: 0.75rem;
      color: #333333;
    }
    .right {
    //   @include wh(4rem, 1.2rem);
      border: 1px solid #dedede;
      display: flex;
      border-radius: 0.2rem;
      box-sizing: border-box;
      span {
        display: block;
        @include wh(2rem, 1.2rem);
        font-size: 0.55rem;
        color: #333333;
        @include CC;
        &:first-child {
          border-right: 1px solid #dedede;
        }
        &.active {
          background-color: #ff4949;
          color: white;
        }
        &.active1 {
          background-color: #6cd245;
          color: white;
        }
      }
    }
  }
  .panel-content {
    padding-bottom: 0.5rem;
    .tip {
      font-size: 0.65rem;
      color: #999999;
      line-height: 1.2rem;
      margin: 0.5rem 0;
    }
    .tip-img {
      display: flex;
      flex-wrap: wrap;
      span {
        @include wh(2.2rem, 2.2rem);
        background-color: #f1f1f1;
        display: block;
        border-radius: 0.2rem;
        margin-bottom: 0.2rem;
        margin-right: 0.2rem;
      }
      /deep/.van-uploader {
        .van-uploader__preview {
          position: relative;
          .van-image {
            @include wh(2.2rem, 2.2rem);
          }
          .van-uploader__preview-delete {
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
          }
        }
        .van-uploader__upload {
          @include wh(2.2rem, 2.2rem);
        }
      }
    }
    .remark {
      margin-top: 0.2rem;
      /deep/.van-field {
        padding: 0;
        .van-field__label {
          width: 2.5rem;
          span {
            font-size: 0.65rem;
            color: #333333;
          }
        }
        textarea {
          font-size: 0.65rem;
          color: #ffa141;
        }
      }
    }
  }
}
</style>
