<template>
  <div class="check-operate-wrapper">
    <!--头部-->
    <OperateHeader />
    <div class="main-content-wrapper">
      <OperatePanel v-for="(item, index) of operateList" :operation='operation'  :key="item.title" :index="index + 1" :data="item" @handleUploadImg="handleUploadImg" @handleSelectCheckStatus="handleSelectCheckStatus" />
    </div>
    <div class="btn-box">
      <div class="input-item">
        <span style="display:block;font-size:0.8rem;padding:0.4rem 0.2rem">该项用时（min）</span>
        <input style="width: 100%; padding: 0.1rem 0.5rem" type="text" :readonly="operation=='no'" v-model="totalTimes" placeholder="用时(分钟)" />
      </div>
      <div class="error-tip" v-if="errorTip">{{ errorTip }}</div>
    </div>
    <div class="btn-box">
      <Button @handleButtonClick="handleCompleteClick" v-show="operation!='no'">完成</Button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
import OperateHeader from './components/OperateHeader'
import OperatePanel from './components/OperatePanel'
import Button from '@/components/Button'
import { getEnterCheckLineDevice, postFinishDeviceCheck } from '@/api/check'
Vue.use(Toast)
export default {
  name: 'CheckOperate',
  components: {
    OperateHeader,
    OperatePanel,
    Button,
  },
  data() {
    return {
      operateList: [],
      totalTimes: '',
      errorTip: '',
      operation: 'yes',
    }
  },
  mounted() {
    this.$route.meta.title = ''
    const { operation } = this.$route.query
    this.operation = operation
    console.log(this.operation)

    this.getEnterCheckLineDevice()
  },
  methods: {
    /**
     * 进入点检任务点检线点检设备任务详细页接口
     */
    getEnterCheckLineDevice() {
      const { taskId, deviceId, deviceName } = this.$route.query
      getEnterCheckLineDevice(taskId, deviceId).then((res) => {
        this.operateList = res.details.dataList.content
        this.totalTimes = res.details.totalTimes
        this.$route.meta.title = deviceName
      })
    },
    /**
     * 完成
     */
    handleCompleteClick() {
      if (!/^\d{1,}$/.test(this.totalTimes)) {
        this.errorTip = '请填写正整数'
        return
      } else {
        this.errorTip = ''
      }
      const { taskId, deviceId, checkOrder } = this.$route.query
      const standardId = this.operateList[0].standardId
      postFinishDeviceCheck(
        taskId,
        deviceId,
        checkOrder,
        standardId,
        this.totalTimes,
        this.operateList
      ).then((res) => {
        if (res.retCode === 1) {
          Toast.success(res.retMsg)
          setTimeout(() => {
            this.$router.go(-1)
          }, 2000)
        } else {
          Toast(res.retMsg)
        }
      })
      // this.$router.push({
      //   path: '/taskList',
      // })
    },
    /**
     * 上传图片
     */
    handleUploadImg(url, id) {
      this.operateList.map((item) => {
        if (item.id === id) {
          let abnormalPicUrl = item.abnormalPicUrl
          if (abnormalPicUrl) {
            const arr = abnormalPicUrl.split(',')
            arr.push(url)
            item.abnormalPicUrl = arr.join(',')
          } else {
            item.abnormalPicUrl = url
          }
        }
        return item
      })
    },
    /**
     * 选择点检状态
     */
    handleSelectCheckStatus(status, id) {
      this.operateList.map((item) => {
        if (item.id === id) {
          item.checkStatus = status
        }
        return item
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';

.check-operate-wrapper {
  width: 100%;
  min-height: 100%;
  background-color: #f1f1f1;
  .main-content-wrapper {
    padding: 1rem;
  }
  .btn-box {
    box-sizing: border-box;
    padding: 0rem 1rem 1rem;
    .error-tip {
      text-align: right;
      color: #ff3333;
      font-size: 0.6rem;
      margin-top: 0.2rem;
    }
  }
}
</style>
